@font-face {
  font-family: "Sirwan6";
  src: local("Sirwan6"),
    url("../src/assets/fonts/sirwan6.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "rudawbold";
  src: local("rudawbold"),
    url("../src/assets/fonts/rudawbold.ttf") format("truetype");
  font-weight: bold;
}
:root {
  --color-bg: #e7f4e8;
  --color-bg-variant: #f2f2f2;
  --color-primary: #151321;
  --color-white: #fff;
  --color-light: rgba(255, 255, 255, 0.6);

  --transition: all 400ms ease;

  --swiper-theme-color: white !important;
}
body {
  font-family: "rudawbold";
}
.br {
  border: 1px solid red;
}

.al-navbar {
  background-color: var(--color-primary);
}
.c {
  display: flex;
  justify-content: center;
  align-items: center;
}
.cc {
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
}
